.big-title-box-container {
  position: relative;
  padding: 120px 80px 80px;

  @include respond-below(xxs) {
    padding-left: 20px;
    padding-right: 20px;
  }

  > .big-title-box-wrapper {
    position: relative;
    @include display-as-flexbox-column-direction-centered();
    @include set-theme-font;

    > .big-title-box-title {
      display: block;
      font-size: 50px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 60px;
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
      overflow-wrap: break-word;

      @include respond-below(sm) {
        font-size: 32px;
        letter-spacing: 0.0064px;
        line-height: 38px;
      }
    }

    > .big-title-box-year {
      display: block;
      font-size: 24px;
      font-weight: 500;
      color: $burgundyColor;
      margin-top: 10px;
      letter-spacing: 12px;
    }
  }
}
