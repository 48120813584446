.ranking-list-container {
  position: relative;
  width: 100%;
  @include border-box;
  padding: 0 80px 110px;

  @include respond-below(sm) {
    padding: 40px 20px 110px;
  }

  > .ranking-list-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    @include display-as-flexbox-column-direction;

    > .ranking-list-item {
      position: relative;
      @include display-as-flexbox-row-direction;
      padding: 40px 0;

      @include respond-below(sm) {
        @include flex-direction(column !important);
      }

      &-row-reverse {
        @include flex-direction(row-reverse);
      }

      > .ranking-list-item-logo {
        position: relative;
        @include flex(1);
        @include display-as-flexbox-column-direction-centered;

        > .ranking-list-item-order {
          font-size: 85px;
          font-weight: 700;
          color: rgba(184, 30, 30, 0.5);
          letter-spacing: 0.85px;
          line-height: 42px;
        }

        > .ranking-list-item-img {
          padding: 17px 17px 0;
          @include border-box;

          > img {
            width: 100%;
            max-width: 351px;
          }
        }

        > .ranking-list-item-location {
          font-size: 14px;
          font-weight: 300;
          line-height: 25px;
          letter-spacing: 0.84px;

          @include respond-below(sm) {
            padding: 0 17px 17px;
          }
        }
      }

      > .ranking-list-item-detail {
        position: relative;
        @include flex(1);
        @include display-as-flexbox-column-direction;
        border-radius: 10px;
        background: #eee;
        padding: 40px;
        @include border-box;
        -webkit-box-shadow: 5px 5px 10px rgba(10, 10, 10, 0.13);
        -moz-box-shadow: 5px 5px 10px rgba(10, 10, 10, 0.13);
        box-shadow: 5px 5px 10px rgba(10, 10, 10, 0.13);

        > .ranking-list-item-content {
          padding: 0 17px 17px;

          @include respond-below(sm) {
            padding-top: 17px;
          }

          > p {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.84px;
            line-height: 25px;

            &:first-child {
              margin-top: 0;
            }

            > strong {
              > em {
                font-style: italic;
              }
            }

            > a {
              color: $lightBurgundyColor;
              text-decoration: none;
              padding-bottom: 0.7px;
              border-bottom: 1px solid rgba(184, 50, 50, 0.3);

              &:hover {
                color: rgba(184, 50, 50, 0.6);
                border-color: rgba(184, 50, 50, 0.15);
              }
            }
          }
        }

        > .ranking-list-item-review-button {
          padding: 17px 17px 0;
          text-align: center;

          @include respond-below(sm) {
            padding-bottom: 17px;
          }

          > a {
            display: inline-block;
            background-color: $lightBurgundyColor;
            padding: 12px 18px;
            text-transform: uppercase;
            text-decoration: none;
            color: $whiteColor;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.15em;
            border-color: $lightBurgundyColor;
            @include transition(all 0.3s ease-in-out);

            &:hover {
              transform: scale(1.05);
              color: $whiteColor;
            }
          }
        }
      }
    }
  }
}
