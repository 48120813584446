$burgundyColor: #b81e1e;
$lightBurgundyColor: #b83232;
$fairBurgundyColor: rgba(184, 30, 30, 0.8);
$midBurgundyColor: rgba(184, 30, 30, 0.9);
$whiteColor: #ffffff;
$softBlackColor: #212121;
$titleBlackColor: #1d1d1d;
$lightBlackColor: rgba(29, 29, 29, 0.7);
$greyColor: #888888;
$blackColor: #000000;
$footerFirstColor: #444444;
$footerSecondColor: #333333;
$footerFirstText: #ffffff80;
$aboutPicEffect: #4b4848;

.custom-row {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;

  &.full-width {
    max-width: 100%;
  }
}

@mixin theme-row-template($type: 'boxed') {
  width: 100%;
  @if $type == 'boxed' {
    max-width: 1200px;
    margin: 0 auto;
  }
  padding: 0 30px;
  @include border-box();
}

.column-flex {
  @include flex(1);

  &.flex-0 {
    @include flex(0 auto);
  }
}

.vertical-padding-10 {
  padding: 10px 0;
}

.vertical-padding-20 {
  padding: 20px 0;
}

.vertical-padding-30 {
  padding: 30px 0;
}

.vertical-padding-40 {
  padding: 40px 0;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

input:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

input:focus::-moz-placeholder {
  color: rgba(0, 0, 0, 0);
}

input:focus::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0);
}

.hide {
  display: none;
}

.responsive {
  max-width: 100%;
  height: auto;
}
