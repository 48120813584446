h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-family: 'Roboto', sans-serif;
  position: relative;
  max-width: 100%;
  width: fit-content;

  &.text-center {
    width: 100%;
    text-align: center;
  }

  a {
    color: darken($burgundyColor, 10%);
    @include transition(all 0.3s ease-in-out);
    &:hover {
      color: $burgundyColor;
    }
  }
  strong {
    font-weight: 800;
  }
  i {
    font-style: italic;
  }
}

li {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
}
ol {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  margin-top: 30px;

  > li {
    list-style: decimal;
  }
}

@mixin fontSizeLineHeightWeight($size, $height, $weight) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
}

h1 {
  margin-bottom: 17px;
  @include fontSizeLineHeightWeight(50px, 60px, 400);
  @include respond-below(sm) {
    @include fontSizeLineHeightWeight(32px, 38px, 400);
  }
}
h2 {
  margin-bottom: 24px;
  letter-spacing: 1.5px;
  @include fontSizeLineHeightWeight(30px, 42px, 400);
  @include respond-below(sm) {
    letter-spacing: 0px;
    @include fontSizeLineHeightWeight(28px, 39px, 400);
  }
}
h3 {
  margin-top: 32px;
  padding: 7px 17px;
  background-color: $burgundyColor;
  color: $whiteColor;
  @include fontSizeLineHeightWeight(22px, 26px, 500);

  &.clear-style {
    padding: 0;
    background: none;
    color: $blackColor;
    @include fontSizeLineHeightWeight(22px, 26px, 400);
    letter-spacing: 1px;
  }
}
h4 {
  margin-top: 22px;
  @include fontSizeLineHeightWeight(22px, 26px, 500);
}
h5,
h6 {
  margin-top: 22px;
  @include fontSizeLineHeightWeight(16px, 25px, 500);
}

p,
li {
  color: $lightBlackColor;
  letter-spacing: 0.84px;
  margin-top: 17px;
  margin-bottom: 17px;
  @include fontSizeLineHeightWeight(14px, 25px, 400);
  @include respond-below(sm) {
    @include fontSizeLineHeightWeight(14px, 25px, 400);
  }
}

.title-separator {
  padding: 15px 0;
  width: 116px;
  margin: 0 auto;
  > hr {
    height: 3px;
    width: 100%;
    background-color: $burgundyColor;
  }
}
.text-area-spacer {
  position: relative;
  width: 100%;
  height: 36px;
}
