@import './responsive';

/*
=======================================
  Media Queries -START-
=======================================
 */
$mq-phone: 480px;
$mq-tablet-min: 481px;
$mq-tablet: 768px;
$mq-tablet-landscape-min: 769px;
$mq-tablet-landscape: 1200px;
$mq-desktop-min: 1201px;

@mixin responsive-mobile {
  @media (max-width: $mq-phone) {
    @content;
  }
}

@mixin responsive-tablet {
  @media (max-width: $mq-tablet) {
    @content;
  }
}

@mixin responsive-tablet-landscape {
  @media (max-width: $mq-tablet-landscape) {
    @content;
  }
}

@mixin responsive-tablet-only {
  @media only screen and (min-width: $mq-tablet-min) and (max-width: $mq-tablet) {
    @content;
  }
}

@mixin responsive-tablet-landscape-only {
  @media only screen and (min-width: $mq-tablet-landscape-min) and (max-width: $mq-tablet-landscape) {
    @content;
  }
}

@mixin responsive-desktop-only {
  @media only screen and (min-width: $mq-desktop-min) {
    @content;
  }
}

/*
=======================================
  Media Queries -END-
=======================================
 */

/*
=======================================
  FLEX -START-
=======================================
 */
// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

/*
=======================================
  FLEX -END-
=======================================
 */

/*
=======================================
  BOX SIZING -START-
=======================================
 */
@mixin border-box() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin content-box() {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/*
=======================================
  BOX SIZING -END-
=======================================
 */

/*
=======================================
  CUSTOM MERGERS -START-
=======================================
 */
@mixin display-as-flexbox-row-direction() {
  @include flexbox();
  @include flex-direction(row);
}

@mixin display-as-flexbox-column-direction() {
  @include flexbox();
  @include flex-direction(column);
}

@mixin display-as-flexbox-row-direction-centered() {
  @include display-as-flexbox-row-direction();
  @include align-items(center);
  @include justify-content(center);
}

@mixin display-as-flexbox-column-direction-centered() {
  @include display-as-flexbox-column-direction();
  @include align-items(center);
  @include justify-content(center);
}

/*
=======================================
  CUSTOM MERGERS -END-
=======================================
 */

/*
=======================================
  TRANSITION -START-
=======================================
 */
@mixin transition($props) {
  -webkit-transition: $props;
  -moz-transition: $props;
  -ms-transition: $props;
  -o-transition: $props;
  transition: $props;
}

/*
=======================================
  TRANSITION -END-
=======================================
 */

/*
=======================================
  PREPARE A TAG -START-
=======================================
 */
@mixin prepare-a-tag() {
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: #000000;
}

/*
=======================================
  PREPARE A TAG -END-
=======================================
 */

/*
=======================================
  FONT PROPERTY -START-
=======================================
 */
@mixin font-function($size, $line-height, $weight) {
  $weight-final: 400;

  @if $weight == light {
    $weight-final: 300;
  } @else if $weight == normal {
    $weight-final: 400;
  } @else if $weight == medium {
    $weight-final: 500;
  } @else if $weight == semibold {
    $weight-final: 600;
  } @else if $weight == bold {
    $weight-final: 700;
  } @else if $weight == bold {
    $weight-final: 800;
  } @else if $weight == black {
    $weight-final: 800;
  } @else {
    $weight-final: 400;
  }

  font-size: $size + px;
  font-weight: $weight-final;
  line-height: $line-height + px;
}

/*
=======================================
  FONT PROPERTY -END-
=======================================
 */

/*
=======================================
  GRADIENTS -START-
=======================================
 */
@mixin two-color-gradient() {
  background: #648af1;
  background: -moz-linear-gradient(left, #648af1 0%, #22bcdc 100%);
  background: -webkit-linear-gradient(left, #648af1 0%, #22bcdc 100%);
  background: linear-gradient(to right, #648af1 0%, #22bcdc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#648af1', endColorstr='#22bcdc', GradientType=1);
}

//@mixin four-color-gradient(){
//  background: rgba(135, 111, 252, 1);
//  background: -moz-linear-gradient(left, rgba(135, 111, 252, 1) 0%, rgba(33, 191, 219, 1) 37%, rgba(33, 191, 219, 1) 73%, rgba(29, 221, 199, 1) 89%, rgba(29, 221, 199, 1) 100%);
//  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(135, 111, 252, 1)), color-stop(37%, rgba(33, 191, 219, 1)), color-stop(73%, rgba(33, 191, 219, 1)), color-stop(89%, rgba(29, 221, 199, 1)), color-stop(100%, rgba(29, 221, 199, 1)));
//  background: -webkit-linear-gradient(left, rgba(135, 111, 252, 1) 0%, rgba(33, 191, 219, 1) 37%, rgba(33, 191, 219, 1) 73%, rgba(29, 221, 199, 1) 89%, rgba(29, 221, 199, 1) 100%);
//  background: -o-linear-gradient(left, rgba(135, 111, 252, 1) 0%, rgba(33, 191, 219, 1) 37%, rgba(33, 191, 219, 1) 73%, rgba(29, 221, 199, 1) 89%, rgba(29, 221, 199, 1) 100%);
//  background: -ms-linear-gradient(left, rgba(135, 111, 252, 1) 0%, rgba(33, 191, 219, 1) 37%, rgba(33, 191, 219, 1) 73%, rgba(29, 221, 199, 1) 89%, rgba(29, 221, 199, 1) 100%);
//  background: linear-gradient(to right, rgba(135, 111, 252, 1) 0%, rgba(33, 191, 219, 1) 37%, rgba(33, 191, 219, 1) 73%, rgba(29, 221, 199, 1) 89%, rgba(29, 221, 199, 1) 100%);
//  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#876ffc', endColorstr='#1dddc7', GradientType=1);
//}
@mixin two-color-gradient-hover() {
  background: #6993ff;
  background: -moz-linear-gradient(left, #6993ff 0%, #27daff 100%);
  background: -webkit-linear-gradient(left, #6993ff 0%, #27daff 100%);
  background: linear-gradient(to right, #6993ff 0%, #27daff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6993ff', endColorstr='#27daff', GradientType=1);
}

/*
=======================================
  GRADIENTS -END-
=======================================
 */

/*
=======================================
  CONTAIN IMAGE BACKGROUND -START-
=======================================
 */
@mixin contain-image-background() {
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/*
=======================================
  CONTAIN IMAGE BACKGROUND -END-
=======================================
 */
/*
=======================================
  BACKGROUND GRADIENT -START-
=======================================
 */
@mixin linear-gradient($from, $to) {
  background: $to;
  background: -moz-linear-gradient(top, $from 0%, $to 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $from),
    color-stop(100%, $to)
  );
  background: -webkit-linear-gradient(top, $from 0%, $to 100%);
  background: -o-linear-gradient(top, $from 0%, $to 100%);
  background: linear-gradient(to bottom, $from 0%, $to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
}

/*
=======================================
  BACKGROUND GRADIENT -END-
=======================================
 */
/*
=======================================
  BACKGROUND SIZE -START-
=======================================
 */

@mixin backgroundsize($size) {
  -moz-background-size: $size; /* Firefox 3.6 */
  -o-background-size: $size; /* Opera 9.5 */
  -webkit-background-size: $size; /* Safari 3.0, Chrome */
  background-size: $size;
}

/*
=======================================
  BACKGROUND SIZE -END-
=======================================
 */

/*
=======================================
  BORDER RADIUS -START-
=======================================
 */

@mixin borderradius($size) {
  -webkit-border-radius: $size;
  -moz-border-radius: $size;
  border-radius: $size;
}

/*
=======================================
  BORDER RADIUS -END-
=======================================
 */

/*
=======================================
  BORDER RADIUS -START-
=======================================
 */

@mixin box-shadow($content) {
  -webkit-box-shadow: $content;
  -moz-box-shadow: $content;
  box-shadow: $content;
}

/*
=======================================
  BORDER RADIUS -END-
=======================================
 */
