@mixin inputCss() {
  background: rgba(255, 255, 255, .3) !important;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  background: #fafafa;
  font-family: sans-serif;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
}

@mixin failMessageCss() {
  display: none;
  position: relative;
  border-radius: 2px;
  background: #cc3b3b;
  color: #fff;
  font-size: 13px;
  line-height: 23px;
  margin: 12px 0;
  padding: 5px 15px 3px 25px;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    height: 10px;
    width: 2px;
    transform: rotate(-45deg);
    background-color: $whiteColor;
    border-radius: 6px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    height: 10px;
    width: 2px;
    transform: rotate(45deg);
    background-color: $whiteColor;
    border-radius: 6px;
  }
}

.contact-us-container {
  position: relative;
  min-height: calc(100vh - 136px);
  margin-top: -50px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;

  > .bg-image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(320deg, rgba(217, 29, 29, .6) 5%, rgba(154, 30, 30, .9) 50%);
  }

  > .contact-us-wrapper {
    position: relative;
    width: 100%;
    @include display-as-flexbox-column-direction-centered;
    padding: 120px 0;
    min-height: calc(100vh - 136px);

    > .contact-us-title {
      position: relative;
      padding: 0 17px 17px;
      width: 85%;
      margin: 0 auto;

      h1 {
        color: $whiteColor;
      }

      p {
        font-size: 20px;
        max-width: 700px;
        margin: auto;
        color: $whiteColor;
      }
    }

    > .form-wrapper {
      width: 85%;
      max-width: 700px;
      margin: 0 auto;
      @include border-box;
      padding: 17px;

      > form {

        > .form-item {
          margin-bottom: 24px;

          > #fail-message{
            :is(&-email,&-subject,&-message,&-name) {
              @include failMessageCss;
            }
          }

          &.form-item-submit {
            text-align: center;
          }

          > label {
            display: block;
            margin-bottom: 6px;

            > .required {
              text-transform: uppercase;
              font-size: 16px;
              font-weight: 300;
              color: $whiteColor;
            }
          }

          > .form-input {
            @include inputCss;
          }

          > textarea {
            @include inputCss;
            min-height: 100px;
            resize: vertical;
            overflow: auto;
          }

          > .submit-button {
            display: inline-block;
            border-width: 0;
            text-align: center;
            outline: none;
            appearance: none;
            text-decoration: none;
            border-radius: 0;
            color: #fff;
            background-color: #b83232;
            border-color: #b83232;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            padding: 14px 35px;
            font-size: 14px;
            transition: all ease .5s;
            cursor: pointer;

            &:hover {
              transform: scale(1.05);
            }
          }

          > .form-item-wrapper {
            position: relative;
            @include display-as-flexbox-row-direction;
            width: 100%;

            > .wrapper {
              @include display-as-flexbox-column-direction;
              width: 100%;

              > input {
                @include inputCss;

                &.last-name {
                  margin-left: 14px;
                  width: calc(100% - 14px);
                }
              }

              > span.caption-text {
                text-transform: uppercase;
                font-size: 12px;
                color: $whiteColor;

                &.last-name {
                  margin-left: 14px;
                }
              }
            }
          }
        }

        > #fail-message-all {
          @include failMessageCss;
        }
      }
    }
  }
}
