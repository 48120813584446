.ranking-method-wrapper {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;

  .ranking-method-container {
    width: 100%;
    position: relative;

    .ranking-method-bg-pic {
      position: relative;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-attachment: fixed;

      .ranking-method-bg-effect {
        transform: translateZ(0);
        width: 100%;
        background: linear-gradient(320deg, rgba(29, 29, 29, 0.75) 0%, rgba(25, 25, 25, 0.95) 70%);
        z-index: 2;
        transform-style: preserve-3d !important;
        @include display-as-flexbox-row-direction-centered();

        .texts-wrapper {
          @include display-as-flexbox-column-direction-centered();
          padding: 120px 80px;
          position: relative;
          width: 100%;
          max-width: 1000px;

          .rank-method-header {
            @include display-as-flexbox-column-direction-centered();

            > h1 {
              margin-bottom: 10px;
              font-weight: 500;
              font-style: normal;
              font-size: 50px;
              letter-spacing: 0.01em;
              line-height: 1.2em;
              text-transform: none;
              color: $whiteColor;
              text-align: center !important;
            }

            > p {
              color: $whiteColor;
              line-height: 1.5em;
              font-size: 20px;
              margin: 0 auto;
              font-weight: 500;
              text-align: center !important;
              padding-bottom: 17px;
            }
          }

          .ranking-method-list {
            @include display-as-flexbox-row-direction-centered();
            max-width: 800px;
            width: 100%;

            @include respond-below(sm){
              @include display-as-flexbox-column-direction();
            }

            > ul {
              padding: 17px;
              list-style: none;
              width: 50%;
              @include respond-below(sm){
                box-sizing: border-box;
                padding: 0 40px;
                width: 100%;
              }

              > li {
                color: $whiteColor;
                font-size: 14px;
                position: relative;
                top: 4px;
                margin: 7px 0;
              }
            }

            ul li::before {
              content: '\2022';
              color: $burgundyColor;
              font-weight: bold;
              font-size: 25px;
              transform: translate(5px, 5px);
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }

          .ranking-method-text {
            text-align: left;
            padding: 17px;

            > p {
              color: $whiteColor;
              font-style: normal;
              font-size: 14px;
              letter-spacing: 0.06em;
              line-height: 1.8em;
              text-transform: none;
              font-weight: 500;

              > a {
                text-decoration: underline;
                color: $whiteColor !important;
              }
            }
          }
        }
      }
    }
  }
}
