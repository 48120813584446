.about-eli-wrapper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 80px;
  background: linear-gradient(320deg, #bb0909 0%, #900000 100%);
  color: $whiteColor;
  text-align: center;
  @include respond-below(md) {
    padding: 60px 24px;
  }

  .about-eli-container {
    width: 100%;
    @include display-as-flexbox-column-direction-centered();

    > .eligibility-text-part {
      @include display-as-flexbox-column-direction-centered();
      margin-bottom: 77px;
      padding: 0 17px;

      > h1 {
        font-size: 50px;
        margin-bottom: 10px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0.01em;
        line-height: 1.2em;
        text-transform: none;
        color: $whiteColor;
        @include respond-below(md) {
          font-size: 32px;
        }
      }

      > p {
        width: 100%;
        font-size: 18px;
        margin: auto;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0.06em;
        line-height: 1.8em;
        text-transform: none;
        max-width: 600px;
        color: $whiteColor;
      }
    }

    > .eligibility-icon-part {
      @include display-as-flexbox-row-direction();
      @include justify-content(space-between);
      @include respond-below(md) {
        @include display-as-flexbox-column-direction-centered();
      }

      > .icon-parts {
        @include display-as-flexbox-column-direction-centered();
        margin-bottom: 17px;
        @include respond-below(md) {
          margin-bottom: 60px;
        }

        > img {
          width: 60px;
          height: 60px;
          margin-bottom: 22px;
        }

        > h2 {
          font-weight: 600;
          font-style: normal;
          font-size: 22px;
          letter-spacing: 0.02em;
          line-height: 1.2em;
          margin-bottom: 22px;
          color: $whiteColor;
        }

        > p {
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
          width: 100%;
          margin: 0 20px;
          letter-spacing: 0.06em;
          color: $whiteColor;
        }
      }
    }
  }
}
