.footer-wrapper {
  width: 100%;
  box-sizing: border-box;
  bottom: 0;

  .footer-container {
    position: relative;
    width: 100%;
    text-align: center;

    .footer-upper {
      width: 100%;
      background-color: $footerFirstColor;
      padding: 20px 0;
      @include respond-below(md) {
        padding: 17px 0;
      }
      > span {
        color: $footerFirstText;
        margin: 0 3px;
      }

      > a {
        text-decoration: none;
        color: $footerFirstText;
        font-weight: 600;
        font-style: normal;
        font-size: 12px;
        letter-spacing: 0.06em;
        line-height: 1.8em;
        text-transform: none;
        cursor: pointer;
        @include respond-below(md) {
          font-size: 10px;
        }
        @include transition(all 0.3s ease-out);
        &:hover {
          color: darken($footerFirstText, 10%);
        }
      }
    }

    .footer-latter {
      width: 100%;
      background-color: $footerSecondColor;
      color: $greyColor;
      font-weight: 600;
      font-style: normal;
      font-size: 10px;
      letter-spacing: 0.06em;
      line-height: 1.8em;
      text-transform: none;
      padding: 13px 0 40px 0;
      cursor: pointer;

      > a {
        text-decoration: none;
        @include transition(all 0.3s ease-out);
        cursor: pointer;
        color: $greyColor;
        &:hover {
          color: $whiteColor;
        }
      }
    }
  }
}
