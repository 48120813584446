@import './reset';
@import './mixins';
@import './variables';
@import './responsive';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@mixin set-theme-font() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

html {
  width: 100%;
  overflow-x: hidden;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

input,
textarea {
  -webkit-appearance: none;
  font-family: 'Montserrat', sans-serif;
}

body {
  overflow-x: hidden;
  min-width: 320px;
  font-family: 'Montserrat', sans-serif;
}

@import 'components/typography';
@import 'components/header';
@import 'components/big-title-box';
@import 'components/textarea';
@import 'components/ranking-list';
@import 'components/sub-hero-buttons';
@import 'components/about-eligibility';
@import 'components/footer';
@import 'components/faq';

@import 'components/hero-area.scss';
@import 'components/contact-form';
@import 'components/about-hero-area';

@import 'components/about-ranking-methodology';
