.hero-area-wrapper {
  width: 100%;
  color: $whiteColor;
  position: relative;
  margin-top: -50px;

  > .hero-area-container {
    .bg-pic {
      width: 100%;
      height: calc(100vh - 86px);
      box-sizing: border-box;
      @include contain-image-background();
      background-attachment: fixed;

      @include respond-below(sm) {
        height: calc(100vh - 57px);
      }

      > .bg-effect {
        width: 100%;
        height: calc(100vh - 86px);
        box-sizing: border-box;
        padding: 80px;
        background-color: rgba(184, 150, 100, 0.4);
        z-index: 2;
        @include display-as-flexbox-row-direction-centered();
        @include respond-below(md) {
          padding: 20px;
        }
        @include respond-below(sm) {
          height: calc(100vh - 57px);
        }

        .main-header-wrapper {
          background: linear-gradient(270deg, rgba(217, 29, 29, 0) 5%, rgba(184, 30, 30, 0.85) 50%);
          width: 100%;
          @include display-as-flexbox-row-direction();

          > .text-area {
            margin: 60px 0 60px 0;
            float: left;
            @include respond-below(md) {
              margin: 20px 0 40px 0;
            }

            > .big-hero-text {
              font-size: 54px;
              line-height: 1em;
              font-weight: 900;
              width: 100%;
              max-width: 1000px;
              @include respond-below(md) {
                font-size: 32px;
              }
            }

            > .small-hero-text {
              max-width: 500px;
              padding-top: 24px;
              font-size: 24px;
              font-weight: 300;
              font-style: normal;
              letter-spacing: 0.06em;
              line-height: 1.8em;
              color: $whiteColor;
              text-transform: none;
              padding-bottom: 34px;
              @include respond-below(md) {
                font-size: 14px;
              }
            }

            > .view-rankings-button {
              text-decoration: none;
              color: $lightBurgundyColor;
              background-color: $whiteColor;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0.15em;
              font-style: normal;
              padding: 14px 28px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .scroll-field {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    @include display-as-flexbox-column-direction-centered();
    cursor: pointer;
    z-index: 99;
    text-decoration: none !important;

    > p {
      color: $whiteColor;
      opacity: 1;
      font-weight: 700;
      font-style: normal;
      font-size: 12px;
      letter-spacing: 0em;
      text-transform: uppercase;
      line-height: 1em;
      text-align: center;
      text-decoration: none;
      @include transition(all 0.3s ease-out);
    }

    > .scroll-line {
      background-color: $whiteColor;
      height: 45px;
      width: 3px;
      opacity: 1;
      @include transition(all 0.3s ease-out);
    }
  }

  .hide-line {
    height: 0 !important;
  }

  .hide-text {
    opacity: 0 !important;
  }
}
