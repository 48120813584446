@import '../mixins';

.header-spacer {
  position: relative;
  width: 100%;
  height: 86px;
  @include respond-below(sm) {
    height: 57px;
  }

  &.index-mobile-header-spacer {
    margin-bottom: 50px;
  }
}

.header-container {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 9999;

  .header-wrapper {
    position: relative;
    width: 100%;
    padding: 25px 70px;
    @include set-theme-font();
    @include display-as-flexbox-row-direction-centered();
    @include justify-content(space-between);
    @include border-box;

    @include respond-below(sm) {
      padding: 19px 20px 20px;
    }

    > .header-logo {
      position: relative;
      @include flex(1);

      @include respond-below(sm) {
        @include flex(0 auto);
      }

      > a.logo {
        display: inline-block;
        text-decoration: none;

        > img {
          width: 100%;
          max-width: 371px;

          @include respond-below(sm) {
            max-width: 272px;
          }
        }
      }
    }

    > .header-menu {
      position: relative;
      @include flex(0 auto);

      @include respond-below(sm) {
        display: none;
      }

      > nav.menu {
        > a {
          display: inline-block;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.96px;
          line-height: 12px;
          text-transform: uppercase;
          text-decoration: none;
          margin: 0 7px;
          padding: 7px 0;
          color: $softBlackColor;

          &.button-link {
            background-color: $burgundyColor;
            padding: 12px 18px;
            color: $whiteColor;
            margin-right: 0;
          }
        }
      }
    }

    > .mobile-menu {
      display: none;

      @include respond-below(sm) {
        display: block;
        margin-top: -20px;

        .nav-bar {
          position: absolute;
          background-color: $whiteColor;
          top: 0;
          left: -100vw;
          height: 100vh;
          width: calc(100% - 60px);
          display: flex;
          transition: 0.5s ease-out;

          &.toggle {
            left: 0;

            > .toggle-menu {
              top: 0;
              right: 0;
              padding: 18px 28px;
              background-color: $burgundyColor;

              > .line {
                background-color: transparent;
                width: 16px;
                height: 1px;
                top: -8px;
                left: 50%;

                &:before {
                  top: 0;
                  height: 16px;
                  width: 1px;
                  transform: rotate(-45deg);
                  background-color: $whiteColor;
                }

                &:after {
                  top: 0;
                  height: 16px;
                  width: 1px;
                  transform: rotate(45deg);
                  background-color: $whiteColor;
                }
              }
            }
          }

          > .toggle-menu {
            position: fixed;
            top: 14px;
            right: 20px;
            width: 4px;
            height: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            > .line {
              position: relative;
              width: 4px;
              height: 4px;
              background-color: $burgundyColor;
              transition: all 0.2s ease-out;

              &:before,
              &:after {
                background-color: $burgundyColor;
                content: '';
                display: block;
                height: 4px;
                width: 100%;
                position: absolute;
                transition: 0.2s ease-out;
              }

              &:after {
                top: -8px;
              }

              &:before {
                top: 8px;
              }
            }
          }

          > .nav-list {
            width: 100%;
            list-style: none;
            padding: 36px;

            > .nav-list-item {
              > .nav-link {
                display: inline-block;
                color: $blackColor;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                text-decoration: none;
                position: relative;
                padding-bottom: 12px;
                text-transform: uppercase;

                &.button-link {
                  background-color: $burgundyColor;
                  padding: 8px 18px;
                  color: $whiteColor;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
