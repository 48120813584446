.faq-container {
  position: relative;
  padding: 0 80px 80px;

  @include respond-below(sm) {
    padding: 0 20px 60px;
  }

  > .faq-wrapper {
    position: relative;
    padding: 40px 17px 17px;
    width: 85%;
    max-width: 900px;
    margin: 0 auto;

    @include respond-below(sm) {
      width: 100%;
      @include border-box;
    }

    > .topic {
      border-top: 1.5px solid rgba(22, 22, 22, 0.2);
      transition: all 0.5s ease;

      &.expanded {
        transition: all 0.3s ease-in-out;
      }

      > .open {
        position: relative;
        cursor: pointer;
        display: block;
        padding: 0;

        > .question {
          padding: 20px 55px 20px 0;
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: $titleBlackColor;
          letter-spacing: 0.8px;
          line-height: 22px;

          &:hover {
            opacity: 0.5;
          }
        }

        > .faq-t {
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          display: inline-block;
          float: right;
          position: absolute;
          top: 24px;
          right: 40px;
          width: 5px;
          height: 5px;
          background: transparent;
          border-left: 2px solid $burgundyColor;
          border-bottom: 2px solid $burgundyColor;
          transition: all 0.3s ease-in-out;
        }

        > .faq-o {
          -moz-transform: rotate(-224deg);
          -ms-transform: rotate(-224deg);
          -webkit-transform: rotate(-224deg);
          transform: rotate(-224deg);
          border-left: 2px solid rgba(184, 30, 30, 0.5);
          border-bottom: 2px solid rgba(184, 30, 30, 0.5);
        }
      }

      > .answer {
        display: none;
        text-align: justify;

        > p {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.84px;
          line-height: 25px;
          color: rgba(29, 29, 29, 0.7);
          margin: 0 0 12px;
        }

        > ol,
        ul {
          margin: 14px 0;
          padding-left: 40px;

          > li {
            color: rgba(29, 29, 29, 0.7);
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.84px;
            line-height: 25px;
            margin: 0;
          }
        }

        > ul {
          list-style: disc;
        }
      }
    }
  }
}
