.sub-hero-wrapper {
  width: 100%;
  position: relative;
  box-sizing: border-box;

  .sub-hero-container {
    @include display-as-flexbox-row-direction-centered();
    width: 100%;
    text-align: center;
    color: $whiteColor;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
    line-height: 1.1em;
    @include respond-below(md) {
      @include display-as-flexbox-column-direction-centered();
    }

    > div {
      width: 100%;
      @include display-as-flexbox-row-direction-centered();
      padding: 24px;

      > .inner-sub {
        display: inline;
        text-align: left;
        padding: 24px;

        > p {
          overflow-wrap: break-word;
          line-height: 14.3px;
          text-align: left;
          width: 100%;
          color: $whiteColor;
        }

        > a {
          text-decoration: none;
          display: inline-block;
          width: 100%;
          color: $whiteColor;
          cursor: pointer;
          vertical-align: center;
          line-height: 20.8px;
          @include display-as-flexbox-row-direction();
          @include justify-content(flex-start);

          > img {
            padding-left: 14px;
            width: 1.4em;
          }
        }
      }
    }

    .left-sub-hero {
      background-color: $burgundyColor;
    }

    .mid-sub-hero {
      background-color: $midBurgundyColor;
    }

    .right-sub-hero {
      background-color: $fairBurgundyColor;
    }
  }
}
