.about-hero-area-wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 100%;

  > .about-hero-area-container {
    width: 100%;

    > .about-bg-pic {
      width: 100%;
      height: 60vh;
      @include contain-image-background();
      background-attachment: fixed;
      background-position: center bottom !important;
      overflow: hidden;

      > .about-bg-effect {
        width: 100%;
        height: 60vh;
        @include display-as-flexbox-row-direction-centered();
        background: linear-gradient(
          321deg,
          rgba(75, 72, 72, 0.3) 0%,
          rgba(29, 28, 28, 0.5) 100%
        ) !important;
        z-index: 2;

        > .about-header-text {
          text-align: center;
          color: $whiteColor;
          font-weight: 500;
          font-style: normal;
          font-size: 50px;
          letter-spacing: 0.01em;
          line-height: 1.2em;
          text-transform: none;

          @include respond-below(sm) {
            font-size: 32px;
          }
        }
      }
    }
  }
}
