.text-area {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  padding-left: 96px;
  padding-right: 96px;

  @include respond-below(lg) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @include respond-below(md) {
    padding-left: 64px;
    padding-right: 64px;
  }
  @include respond-below(sm) {
    padding-left: 52px;
    padding-right: 52px;
  }
  @include respond-below(xs) {
    padding-left: 36px;
    padding-right: 36px;
  }

  > .wrapper {
    position: relative;
    width: 85%;
    margin: 0 auto;
    @include respond-below(sm) {
      width: 100%;
    }
  }
}
